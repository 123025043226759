import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('WhitePaper'),
        href: 'https://www.holdex.finance/whitepaper/HoldexFinanceWhitepaper.pdf',
      },
      {
        label: t('Projects'),
        href: 'https://www.holdex.finance/',
      },
      {
        label: t('Holdex Token'),
        href: 'https://docs.holdex.finance/',
      },
      // {
      //   label: t('Community'),
      //   href: 'https://docs.pancakeswap.finance/contact-us/telegram',
      // },
      // {
      //   label: t('HOLDEX token'),
      //   href: 'https://docs.pancakeswap.finance/tokenomics/cake',
      // },
      // {
      //   label: '—',
      // },
      // {
      //   label: t('Online Store'),
      //   href: 'https://pancakeswap.creator-spring.com/',
      //   isHighlighted: true,
      // },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Customer Support'),
        href: 'https://t.me/holdextokensupport',
      },
      {
        label: t('Docs'),
        href: 'https://docs.holdex.finance/',
      },
      // {
      //   label: t('Guides'),
      //   href: 'https://docs.pancakeswap.finance/get-started',
      // },
    ],
  },
  // {
  //   label: t('Developers'),
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/pancakeswap',
  //     },
  //     {
  //       label: t('Documentation'),
  //       href: 'https://docs.pancakeswap.finance',
  //     },
  //     {
  //       label: t('Bug Bounty'),
  //       href: 'https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty',
  //     },
  //     {
  //       label: t('Audits'),
  //       href: 'https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited',
  //     },
  //     {
  //       label: t('Careers'),
  //       href: 'https://docs.pancakeswap.finance/hiring/become-a-chef',
  //     },
  //   ],
  // },
]
export const socials = [
  {
    label: "Twitter",
    icon: "Twitter",
    href: "https://twitter.com/test",
  },
  {
    label: "Telegram",
    icon: "Telegram",
    items: [
      {
        label: "English",
        href: "https://t.me/pancakeswap",
      },
      {
        label: "Bahasa Indonesia",
        href: "https://t.me/PancakeSwapIndonesia",
      },
      {
        label: "中文",
        href: "https://t.me/PancakeSwap_CN",
      },
      {
        label: "Tiếng Việt",
        href: "https://t.me/PancakeSwapVN",
      },
      {
        label: "Italiano",
        href: "https://t.me/pancakeswap_ita",
      },
      {
        label: "русский",
        href: "https://t.me/pancakeswap_ru",
      },
      {
        label: "Türkiye",
        href: "https://t.me/pancakeswapturkiye",
      },
      {
        label: "Português",
        href: "https://t.me/PancakeSwapPortuguese",
      },
      {
        label: "Español",
        href: "https://t.me/PancakeswapEs",
      },
      {
        label: "日本語",
        href: "https://t.me/pancakeswapjp",
      },
      {
        label: "Français",
        href: "https://t.me/pancakeswapfr",
      },
      {
        label: "Announcements",
        href: "https://t.me/PancakeSwapAnn",
      },
      {
        label: "Whale Alert",
        href: "https://t.me/PancakeSwapWhales",
      },
    ],
  },
  {
    label: "Reddit",
    icon: "Reddit",
    href: "https://reddit.com/r/pancakeswap",
  },
  {
    label: "Instagram",
    icon: "Instagram",
    href: "https://instagram.com/pancakeswap_official",
  },
  {
    label: "Github",
    icon: "Github",
    href: "https://github.com/pancakeswap/",
  },
];
