import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@pancakeswap/uikit'
import Page from '../Layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
  
.preloader { position: fixed !important; top: 0; left: 0; right: 0; bottom: 0; z-index: 10000; }

.preloader:before, .preloader:after { position: absolute; left: 0; height: 51%; width: 100%; transition: all .6s ease;  content: ''; }

.preloader:before { top: 0; }

.page-loaded .preloader:before { transform: translate(0, -100%); }

.preloader:after { bottom: 0; }

.page-loaded .preloader:after { transform: translate(0, 100%); }

.preloader.no-default { background-color: #0b0c22; }

.page-loaded .preloader.no-default:before, .page-loaded .preloader.no-default:after { transform: translate(0, 0); }

.preloader.no-default:before, .preloader.no-default:after { position: absolute; top: 0; left: 0; height: 100%; transition: all .6s; background-color: #0b0c22; }

.preloader-alt:after, .preloader-alt:before { background: #10122d; }

.page-loaded .preloader.no-split:before, .page-loaded .preloader.no-split:after { transform: translate(0, 0); }

.spinner { display: block; position: relative; left: 50%; top: 50%; width: 70px; height: 70px; margin: -35px 0 0 -35px; z-index: 1001; }

.spinner-round { border-radius: 50%; border: 2px solid transparent; border-top-color: #46bdf4; animation: spin 2s linear infinite; }

.spinner-round:before, .spinner-round:after { content: ""; position: absolute; border-radius: 50%; border: 3px solid transparent; }

.spinner-round:before { top: 5px; left: 5px; right: 5px; bottom: 5px; border-top-color: #2b56f5; animation: spin 3s linear infinite; }

.spinner-round:after { top: 15px; left: 15px; right: 15px; bottom: 15px; border-top-color: #0b0c22; animation: spin 1.5s linear infinite; }

.spinner-alt { border-radius: 50%; width: 150px; height: 150px; margin: -75px 0 0 -75px; }

.spinner-alt:after, .spinner-alt:before { content: ""; position: absolute; border-radius: 50%; top: 0; left: 0; right: 0; bottom: 0; }

.spinner-alt:before { border: 1px solid rgba(255, 255, 255, 0.1); }

.spinner-alt:after { border: 1px solid; border-top-color: #fff; border-right-color: transparent; border-left-width: 0; border-bottom-width: 0; animation: spin 1.5s linear infinite; }

.spinner-alt.load-done { transition: all .4s; transform: scale(3); opacity: 0; border-color: transparent; }

.spinner-alt.load-done:after, .spinner-alt.load-done:before { opacity: 0; }

.spinner-alt.spinner-light { border: 1px solid rgba(10, 16, 21, 0.1); }

.spinner-alt.spinner-light:after { border-top-color: #0a1015; }

.spinner-brand { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100px; }

@keyframes spin { 0% { -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      {/* <Spinner /> */}
      <div className="preloader"><span className="spinner spinner-round load-done" /></div>
    </Wrapper>
  )
}

export default PageLoader
