import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Holdex Swap',
  description:
    'The most popular AMM on BSC by user count! Earn HOLDEX through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by Holdex Swap), NFTs, and more, on a platform you can trust.',
  image: 'images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Holdex Swap')}`,
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('Holdex Swap')}`,
      }
    case '/add':
      return {
        title: `${t('Add Liquidity')} | ${t('Holdex Swap')}`,
      }
    case '/remove':
      return {
        title: `${t('Remove Liquidity')} | ${t('Holdex Swap')}`,
      }
    case '/liquidity':
      return {
        title: `${t('Liquidity')} | ${t('Holdex Swap')}`,
      }
    case '/find':
      return {
        title: `${t('Import Pool')} | ${t('Holdex Swap')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Holdex Swap')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Holdex Swap')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `${t('Leaderboard')} | ${t('Holdex Swap')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Holdex Swap')}`,
      }
    case '/farms/auction':
      return {
        title: `${t('Farm Auctions')} | ${t('Holdex Swap')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Holdex Swap')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Holdex Swap')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Holdex Swap')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Holdex Swap')}`,
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('Holdex Swap')}`,
      }
    case '/voting/proposal':
      return {
        title: `${t('Proposals')} | ${t('Holdex Swap')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `${t('Make a Proposal')} | ${t('Holdex Swap')}`,
      }
    case '/info':
      return {
        title: `${t('Overview')} | ${t('Holdex Swap Info & Analytics')}`,
        description: 'View statistics for Holdex Swap exchanges.',
      }
    case '/info/pools':
      return {
        title: `${t('Pools')} | ${t('Holdex Swap Info & Analytics')}`,
        description: 'View statistics for Holdex Swap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `${t('Tokens')} | ${t('Holdex Swap Info & Analytics')}`,
        description: 'View statistics for Holdex Swap exchanges.',
      }
    case '/nfts':
      return {
        title: `${t('Overview')} | ${t('Holdex Swap')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')} | ${t('Holdex Swap')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')} | ${t('Holdex Swap')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Holdex Squad')} | ${t('Holdex Swap')}`,
      }
    default:
      return null
  }
}
